import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Device = () => import('@/entities/device/device.vue');
// prettier-ignore
const DeviceUpdate = () => import('@/entities/device/device-update.vue');
// prettier-ignore
const DeviceDetails = () => import('@/entities/device/device-details.vue');
// prettier-ignore
const PassTypeIdentifier = () => import('@/entities/pass-type-identifier/pass-type-identifier.vue');
// prettier-ignore
const PassTypeIdentifierUpdate = () => import('@/entities/pass-type-identifier/pass-type-identifier-update.vue');
// prettier-ignore
const PassTypeIdentifierDetails = () => import('@/entities/pass-type-identifier/pass-type-identifier-details.vue');
// prettier-ignore
const Pass = () => import('@/entities/pass/pass.vue');
// prettier-ignore
const PassUpdate = () => import('@/entities/pass/pass-update.vue');
// prettier-ignore
const PassDetails = () => import('@/entities/pass/pass-details.vue');
// prettier-ignore
const RegisteredPasses = () => import('@/entities/registered-passes/registered-passes.vue');
// prettier-ignore
const RegisteredPassesUpdate = () => import('@/entities/registered-passes/registered-passes-update.vue');
// prettier-ignore
const RegisteredPassesDetails = () => import('@/entities/registered-passes/registered-passes-details.vue');
// prettier-ignore
const Customer = () => import('@/entities/customer/customer.vue');
// prettier-ignore
const CustomerUpdate = () => import('@/entities/customer/customer-update.vue');
// prettier-ignore
const CustomerDetails = () => import('@/entities/customer/customer-details.vue');
// prettier-ignore
const Statistics = () => import('@/entities/statistics/statistics.vue');
// prettier-ignore
const StatisticsUpdate = () => import('@/entities/statistics/statistics-update.vue');
// prettier-ignore
const StatisticsDetails = () => import('@/entities/statistics/statistics-details.vue');
// prettier-ignore
const Token = () => import('@/entities/token/token.vue');
// prettier-ignore
const TokenUpdate = () => import('@/entities/token/token-update.vue');
// prettier-ignore
const TokenDetails = () => import('@/entities/token/token-details.vue');
// prettier-ignore
const SportMapping = () => import('@/entities/sport-mapping/sport-mapping.vue');
// prettier-ignore
const SportMappingUpdate = () => import('@/entities/sport-mapping/sport-mapping-update.vue');
// prettier-ignore
const SportMappingDetails = () => import('@/entities/sport-mapping/sport-mapping-details.vue');
// prettier-ignore
const Match = () => import('@/entities/match/match.vue');
// prettier-ignore
const MatchUpdate = () => import('@/entities/match/match-update.vue');
// prettier-ignore
const MatchDetails = () => import('@/entities/match/match-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'device',
      name: 'Device',
      component: Device,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device/new',
      name: 'DeviceCreate',
      component: DeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device/:deviceId/edit',
      name: 'DeviceEdit',
      component: DeviceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'device/:deviceId/view',
      name: 'DeviceView',
      component: DeviceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pass-type-identifier',
      name: 'PassTypeIdentifier',
      component: PassTypeIdentifier,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pass-type-identifier/new',
      name: 'PassTypeIdentifierCreate',
      component: PassTypeIdentifierUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pass-type-identifier/:passTypeIdentifierId/edit',
      name: 'PassTypeIdentifierEdit',
      component: PassTypeIdentifierUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pass-type-identifier/:passTypeIdentifierId/view',
      name: 'PassTypeIdentifierView',
      component: PassTypeIdentifierDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pass',
      name: 'Pass',
      component: Pass,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pass/new',
      name: 'PassCreate',
      component: PassUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pass/:passId/edit',
      name: 'PassEdit',
      component: PassUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'pass/:passId/view',
      name: 'PassView',
      component: PassDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registered-passes',
      name: 'RegisteredPasses',
      component: RegisteredPasses,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registered-passes/new',
      name: 'RegisteredPassesCreate',
      component: RegisteredPassesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registered-passes/:registeredPassesId/edit',
      name: 'RegisteredPassesEdit',
      component: RegisteredPassesUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'registered-passes/:registeredPassesId/view',
      name: 'RegisteredPassesView',
      component: RegisteredPassesDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer',
      name: 'Customer',
      component: Customer,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer/new',
      name: 'CustomerCreate',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer/:customerId/edit',
      name: 'CustomerEdit',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer/:customerId/view',
      name: 'CustomerView',
      component: CustomerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'statistics',
      name: 'Statistics',
      component: Statistics,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'statistics/new',
      name: 'StatisticsCreate',
      component: StatisticsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'statistics/:statisticsId/edit',
      name: 'StatisticsEdit',
      component: StatisticsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'statistics/:statisticsId/view',
      name: 'StatisticsView',
      component: StatisticsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'token',
      name: 'Token',
      component: Token,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'token/new',
      name: 'TokenCreate',
      component: TokenUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'token/:tokenId/edit',
      name: 'TokenEdit',
      component: TokenUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'token/:tokenId/view',
      name: 'TokenView',
      component: TokenDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sport-mapping',
      name: 'SportMapping',
      component: SportMapping,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sport-mapping/new',
      name: 'SportMappingCreate',
      component: SportMappingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sport-mapping/:sportMappingId/edit',
      name: 'SportMappingEdit',
      component: SportMappingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'sport-mapping/:sportMappingId/view',
      name: 'SportMappingView',
      component: SportMappingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match',
      name: 'Match',
      component: Match,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match/new',
      name: 'MatchCreate',
      component: MatchUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match/:matchId/edit',
      name: 'MatchEdit',
      component: MatchUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'match/:matchId/view',
      name: 'MatchView',
      component: MatchDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
